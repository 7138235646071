import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageWrapper from "../components/shared/PageWrapper"

const LoginPage = () => (
  <Layout>
    <Seo title="Login" />
    <PageWrapper prose>
      <h2 className="text-2xl text-indigo-600">Student Portal</h2>
      <p className="mb-5">
        Current students can log in to the student portal below:
      </p>
      <iframe
        id="sb_login_if"
        src="https://app.mymusicstaff.com/Widget/v2/Login.aspx?Sandboxed=false"
        allowtransparency="true"
        frameborder="0"
        style={{
          width: "100%",
          overflow: "hidden",
          height: "400px",
          maxWidth: "600px",
        }}
        height="600px"
        scrolling="no"
      ></iframe>
    </PageWrapper>
  </Layout>
)

export default LoginPage
